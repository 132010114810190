/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f0f2f5;
  color: #333;
  line-height: 1.6;
}

/* Container for the app */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

/* Header styling */
.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 4rem;
  color: #333;
}

.header h2 {
  font-size: 1.5rem;
  color: #555;
  margin-top: 20px;
}

.header p {
  font-size: 0.9rem;
  color: #777;
  margin-top: 5px;
}

/* Input styling */
.input {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.input:focus {
  border-color: #63E6BE;
  outline: none;
}

/* Select styling */
select {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  appearance: none;
  background-color: #fff;
  transition: border-color 0.3s;
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjNEM0QzUwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2Ij48cGF0aCBkPSJNMS41NyAxTDUgNC40MyA4LjQzIDF6Ii8+PC9zdmc+");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

select:focus {
  border-color: #63E6BE;
  outline: none;
}

select option {
  padding: 10px;
}

/* Button styling */
.button {
  background-color: #63E6BE;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #52d3a3;
}

.button:active {
  background-color: #42c093;
}

/* Result styling */
.result {
  margin-top: 20px;
  text-align: center;
}

.result p {
  font-size: 1.5rem;
  margin-top: 10px;
}

/* Media queries for responsiveness */
@media (max-width: 600px) {
  .header h1 {
    font-size: 2.5rem;
  }

  .header h2 {
    font-size: 1.3rem;
  }

  .input, .button, select {
    width: 90%;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

th, td {
  padding: 12px 15px;
  text-align: center;
  border: 1px solid #ddd;
}

th {
  background-color: #4CAF50;
  color: white;
  text-transform: uppercase;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f1f1f1;
}

a {
  text-decoration: none;
  color: #2196F3;
  font-weight: bold;
}

a:hover {
  color: #0056b3;
}

img {
  border-radius: 50%;
  border: 2px solid #ddd;
}

td {
  vertical-align: middle;
}

h6 {
  text-align: center;
}